import React, { useEffect } from "react";
import { Box, Button, Paper } from "@material-ui/core";

import { ErrorBoundary } from "react-error-boundary";
import { Prompt } from "react-router";
import { SettingsEventsTable } from "./SettingsEventsTable";
import { ErrorBoundaryFallback } from "../../../../components/customComponents/ErrorBoundaryFallback";
import { UploadComponent } from "./UploadComponent";
import { LinearProgressWithLabel } from "../../../../components/customComponents/LinearProgressWithLabel";
import { ProgressIndicators } from "./ProgressIndicators";
import { UploadState } from "./progressStatesHelper";
import { apiGet } from "../../../../api/actions";
import { API_UPLOAD_STATES, USER_SERVICE_URL } from "../../../../api/apiConstants";
import { useProfileStyles } from "../SettingsPages/Profile";

export const UploadPage = (): JSX.Element => {
  const [progress, setProgress] = React.useState<number>(0);
  const [uploadStates, setUploadStates] = React.useState<UploadState[]>([]);
  const [uploadStatesLoading, setUploadStatesLoading] = React.useState<boolean>(false);
  const takeoutInstructionsURL = "https://www.llif.org/life-takeout-guide/#guides";
  const fetchProgressStatus = () => {
    setUploadStatesLoading(true);
    apiGet(API_UPLOAD_STATES, {}, {}, USER_SERVICE_URL)
      .then((response) => {
        setUploadStates(response.data);
      })
      .catch(() => {
        setUploadStates([]);
      })
      .finally(() => {
        setUploadStatesLoading(false);
      });
  };

  useEffect(fetchProgressStatus, []);
  const classes = useProfileStyles();

  return (
    <Paper elevation={0} className={classes.paper}>
      <Box fontSize="h4.fontSize" className={classes.center} fontWeight={700} mt={3} mb={1.5}>
        Recover Your Data With Our Data Takeout Guides
      </Box>

      <Box fontSize="p.fontSize" mt={3} mb={1.5}>
        The data you generate creates measurable value that can be harnessed to improve your quality of life. Live Learn
        Innovate will help you to understand the value you create and how to maintain it. You can utilize your data to
        make lifestyle changes, improve family care, remote elderly care, and more.{" "}
      </Box>
      <Box fontSize="p.fontSize" fontWeight={700} mt={3} mb={1.5}>
        With these reference guides, you will be walked through how to recover your data from major data providers. It
        can then be loaded into your data dashboard for your review.
      </Box>

      <Box fontSize="h4.fontSize" className={classes.center} fontWeight={700} mt={3} mb={5}>
        <Button variant="contained" color="primary" href={takeoutInstructionsURL} target="_blank">
          Takeout Guide
        </Button>
      </Box>
      <Paper>
        {/* Upload Component */}
        <ErrorBoundary fallbackRender={(props) => <ErrorBoundaryFallback {...props} title="upload component" />}>
          <UploadComponent setProgress={setProgress} fetchProgressStatus={fetchProgressStatus} />
        </ErrorBoundary>

        {/* Progress meter */}
        {progress !== 0 && <LinearProgressWithLabel value={progress} />}

        <ErrorBoundary fallbackRender={(props) => <ErrorBoundaryFallback {...props} title="progress indicators" />}>
          <ProgressIndicators
            uploadStates={uploadStates}
            fetchProgressStatus={fetchProgressStatus}
            uploadStatesLoading={uploadStatesLoading}
          />
        </ErrorBoundary>

        {/* Log entries */}
        <ErrorBoundary fallbackRender={(props) => <ErrorBoundaryFallback {...props} title="upload table logs" />}>
          <SettingsEventsTable />
        </ErrorBoundary>
      </Paper>
      <Prompt when={progress !== 0} message={() => `You have a pending upload. Are you sure you want to leave?`} />
    </Paper>
  );
};
