import React, { useEffect, useReducer, useState } from "react";
import { AxiosResponse } from "axios";
import { useSnackbar } from "notistack";
import { uploadFile } from "../../../../helpers/services/fileUploaderService";
import {
  INIT_STATE,
  providerDetector,
  reducer,
  SET_PROVIDER_STATE,
  UNSET_PROVIDER_STATE,
  unsuccessfulUploadString,
  validateUpload,
} from "./settingsPageUploadHelper";
import { ProviderSelectionDialog } from "./ProviderSelectionDialog";

export interface ProviderSelectionComponentInterface {
  fileList: File[];
  supportedProviders: string[];
  setUploadDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setFileList: React.Dispatch<React.SetStateAction<File[]>>;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  fetchProgressStatus: () => void;
}

export const FileProcessor = ({
  fileList,
  supportedProviders,
  setUploadDisabled,
  setFileList,
  setProgress,
  fetchProgressStatus,
}: ProviderSelectionComponentInterface) => {
  const [currFileIndex, setCurrFileIndex] = useState<number>(0);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [currProvider, setCurrProvider] = useState<string>("");
  const [availableProviders, dispatch] = useReducer(reducer, {});

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (supportedProviders.length) {
      dispatch({ type: INIT_STATE, supportedProviders });
    }
  }, [supportedProviders]);

  /**
   * Triggered when files are added, or when we increment the file index
   */
  useEffect(() => {
    fileListChangeHandler();
  }, [fileList, currFileIndex]);

  useEffect(() => {
    allFilesCompleteHandler();
  }, [currFileIndex]);

  /**
   * Triggered when user selects a cloud provider
   */
  useEffect(() => {
    // So we know there was a file the user selected but we didn't know the provider
    if (currProvider.length) {
      // Ensure they clicked a valid provider and validate the file that's selected again with the current index.
      if (validateUpload(fileList[currFileIndex])) {
        uploadHandler(currProvider);
        setCurrFileIndex(currFileIndex + 1);
        setDialogOpen(false);
        setCurrProvider("");
      }
    }
  }, [currProvider]);

  const uploadHandler = (provider: string) => {
    if (availableProviders?.[provider]?.used) {
      enqueueSnackbar(`Couldn't upload ${fileList?.[currFileIndex]?.name}. ${provider} upload already pending`, {
        variant: "warning",
      });
      return;
    }

    dispatch({ type: SET_PROVIDER_STATE, provider });
    enqueueSnackbar(`Started upload for '${fileList[currFileIndex].name}'`, { variant: "info" });

    uploadFile(fileList[currFileIndex] as File, provider, progressHandler)
      .then((response: AxiosResponse) => {
        enqueueSnackbar(`${response.data.message} '${fileList[currFileIndex].name}'`, { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(`${unsuccessfulUploadString} '${fileList[currFileIndex].name}'`, { variant: "error" });
      })
      .finally(() => {
        dispatch({ type: UNSET_PROVIDER_STATE, provider });
        setProgress(0);
        fetchProgressStatus();
      });
  };

  const allFilesCompleteHandler = () => {
    if (currFileIndex >= fileList.length && fileList.length !== 0) {
      setDialogOpen(false);
      setUploadDisabled(false);
      setCurrFileIndex(0);
      setCurrProvider("");
      setFileList([]);
    }
  };

  const progressHandler = (progressEvent: ProgressEvent<EventTarget>) => {
    const currentProgress = Math.floor((progressEvent.loaded / progressEvent.total) * 100);
    setProgress(currentProgress);
  };

  /**
   * Handles when user adds files
   *
   * The current file is tracked with the state var currFileIndex.
   * This effectively works as a forEach file with ability to prompt user for provider if unknown
   */
  const fileListChangeHandler = () => {
    // Ensure that there are some files selected already
    if (fileList.length) {
      setUploadDisabled(true);

      // Grab the current file
      const file: File = fileList[currFileIndex];
      // Ensure that the size, extension, etc are valid
      if (validateUpload(file)) {
        // See if we can detect the provider
        const detectedProvider = providerDetector(file, supportedProviders);
        if (detectedProvider) {
          // if we have a valid file and are able to determine the source, then we can upload that file directly.
          uploadHandler(detectedProvider);
          // Meanwhile, while that's uploading, we can move on to the next file.
          setCurrFileIndex(currFileIndex + 1);
        } else {
          // If we weren't able to determine provider, open user prompt
          setDialogOpen(true);
        }
      }
    }
  };

  return (
    <ProviderSelectionDialog
      isDialogOpen={isDialogOpen}
      fileList={fileList}
      currFileIndex={currFileIndex}
      supportedProviders={supportedProviders}
      setCurrProvider={setCurrProvider}
      setCurrFileIndex={setCurrFileIndex}
    />
  );
};
