import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import { GridColDef, GridSortDirection, GridValueGetterParams } from "@material-ui/data-grid";
import { LinearProgress } from "@material-ui/core";
import { XGrid } from "@material-ui/x-grid";
import { ObjectType } from "../../../../types/graphs/ObjectType";
import { getAction } from "../../../../helpers/actions/actions";
import { API_USER_LOGS, USER_SERVICE_URL } from "../../../../api/apiConstants";
import { timestampKey } from "../../../../types/responses/NewESResponseDataType";

const sortModel = [
  {
    field: "timestamp",
    sort: "desc" as GridSortDirection,
  },
];

const columns: GridColDef[] = [
  {
    field: "timestamp",
    headerName: "Date",
    width: 220,
    type: "dateTime",
    valueGetter: (params: GridValueGetterParams) =>
      `${params.value ? format(new Date(params.value as number), "E LLL do yyyy, h:mm bb") : 0}`,
    sortComparator: (_v1, _v2, cellParams1, cellParams2) => {
      return cellParams1.id > cellParams2.id ? 1 : 0;
    },
  },
  { field: "action", headerName: "Action", width: 125 },
  { field: "provider", headerName: "Provider", width: 125 },
  { field: "filename", headerName: "File Name", flex: 1 },
  { field: "events", headerName: "Events", flex: 1 },
];

export const SettingsEventsTable = () => {
  const [logsData, setLogsData] = useState<ObjectType>();
  const [displayLogs, setDisplayLogs] = useState<boolean>(false);

  useEffect(() => {
    getAction(API_USER_LOGS, setLogsData, USER_SERVICE_URL).then(() => {
      setDisplayLogs(true);
    });
  }, []);

  return (
    <>
      {displayLogs ? (
        <div style={{ height: 400 }}>
          {logsData?.Values ? (
            <XGrid
              sortModel={sortModel}
              rows={logsData?.Values}
              columns={columns}
              pageSize={10}
              getRowId={(row) => row[timestampKey]}
            />
          ) : (
            <LinearProgress />
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
